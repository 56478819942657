function transformOptions(data) {
  const {
    deviceInfo: { uuid },
    account,
    currentQuality,
    contentItem,
    title,
    houseId,
    contentType
  } = data

  let updatedTitle = title
  const { episodeNumber, seasonNumber } = contentItem
  if (episodeNumber && seasonNumber) {
    updatedTitle = `${title} S${seasonNumber}E${episodeNumber}`
  }

  // will send undefined to youbora when user is guest
  let cpCustomerID
  let selectedProfile
  if (account) {
    cpCustomerID = account.cpCustomerID
    selectedProfile = account.selectedProfile
  }

  return {
    // ignore ads event being auto-collected, as it's causing wrong PAUSE event
    // https://documentation.npaw.com/integration-docs/v1.0/docs/setting-options-and-metadata#ad-metadata
    'ad.ignore': true,
    enableAnalytics: true,
    username: cpCustomerID,
    'content.isLive': false,
    'content.title': updatedTitle,
    'content.cdn': 'Akamai',
    // below params are mapped in youbora console: https://suite.npaw.com/v/lightbox/admin/data/custom_dimensions
    'extraparam.1': null, // Chromecast Type
    'extraparam.2': currentQuality.label, // Data Saver
    'extraparam.3': contentType, // Content Type
    'extraparam.4': selectedProfile, // Profile ID
    'extraparam.5': 'e8ff1b8fc6dd69d54cf623eb425f34932a423f34', // App Version
    'extraparam.6': uuid, // Device UUID
    'extraparam.7': houseId, // Media House ID
    'extraparam.8': null, // Network Type
    'extraparam.9': 'Web', // Application
    'extraparam.12': 'Web' // Ad Endpoint Detail
  }
}

export default transformOptions
